import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-empty-card',
  templateUrl: './empty-card.component.html',
  styleUrls: ['./empty-card.component.scss'],
})
export class EmptyCardComponent implements OnInit {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() description?: string;
  @Input() link?: string;
  @Input() fullWidthAlways?: boolean;
  @Input() loading = false;
  @Input() icon = 'add';

  @Input() buttonText = 'Hinzufügen';

  @Output() triggerAction = new EventEmitter<void>();

  outerGridItemClasses = '';

  ngOnInit(): void {
    this.outerGridItemClasses = `flex flex-col col-span-12${this.fullWidthAlways ? '' : ' lg:col-span-8 lg:col-start-3'}`;
  }

  onAction() {
    this.triggerAction.emit();
  }

  getIcon() {
    return this.loading ? 'progress_activity' : this.icon;
  }
}
