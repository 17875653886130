import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GeocodeResult, PlaceType2 } from '@googlemaps/google-maps-services-js';
import {
  ReplaySubject,
  Observable,
  switchMap,
  from,
  map,
  startWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  EMPTY,
  skip,
  interval,
} from 'rxjs';
import { LocationsService } from '../../services/locations.service';
import { SchoolLevels, SchoolLevelDescriptions } from '../../types/school';
import { Hit } from '@algolia/client-search';
import {
  Job,
  JobFunctionsAlternative,
  JobFunctionsLeadership,
  JobFunctionsPedagogic,
  Subjects,
} from '../../types/job';
import { JobService } from '../../services/job.service';
import { getUnixTime, fromUnixTime, addMinutes } from 'date-fns';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PublicToolbarService } from '../../services/public-toolbar.service';
import { SchoolService } from '../../services/school.service';
import { pushIfNotExists } from '../../core/helpers';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit, AfterViewInit {
  placeholderIndex = 0;
  placeholderOptions = [
    'Suche',
    'Berufsbezeichnung',
    'Orte',
    'Beschäftigungsart',
    'Sonstiges',
  ];

  page = 0;
  maxPage = 0;
  showFilters = false;

  readonly defaultHitsPerPage = 32;
  readonly possibleRadius = [20, 30, 50, 100];

  fetch$ = new ReplaySubject(1);
  jobs: Hit<Job>[] = [];
  locations$: Observable<GeocodeResult[]> = EMPTY;

  selectedLocation?: GeocodeResult;
  place?: unknown;

  controls = {
    serach: new FormControl<string>(''),
    location: new FormControl<string>(''),
    radius: new FormControl<number | undefined>(undefined),
    isFulltimeJob: new FormControl<boolean | null>(null),
    jobFunctions: new FormControl<string[]>([]),
    levels: new FormControl<(typeof SchoolLevels)[number][]>([]),
    levelDescriptions: new FormControl<
      (typeof SchoolLevelDescriptions)[number][]
    >([]),
    startTime: new FormControl<Date | null>(null),
    endTime: new FormControl<Date | null>(null),
  };

  // subjects
  selectedSubjects: (typeof Subjects)[] = [];
  filteredSubjects$: Observable<(typeof Subjects)[number][]> = EMPTY;
  subjectControl = new FormControl<string>('');
  reloadFilteredSubjects$ = new ReplaySubject(1);
  subjects = Subjects;

  // form field values
  jobFunctionsPedagogic = JobFunctionsPedagogic;
  jobFunctionsLeadership = JobFunctionsLeadership;
  jobFunctionsAlternative = JobFunctionsAlternative;

  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;
  minDate = new Date();

  constructor(
    private locationsService: LocationsService,
    private schoolService: SchoolService,
    private jobService: JobService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    toolbarConfig: PublicToolbarService
  ) {
    toolbarConfig.set({
      showBoth: true,
    });
  }

  async ngOnInit() {
    interval(3000).subscribe(() => {
      if (this.placeholderOptions.length - 1 === this.placeholderIndex) {
        this.placeholderIndex = 0;
      } else {
        this.placeholderIndex++;
      }
    });

    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
    this.setInputFieldsFromQueryParams(queryParamMap);
    this.registerListeners();
    await this.loadInitalData(queryParamMap);

    this.activatedRoute.queryParamMap
      .pipe(skip(1), debounceTime(300))
      .subscribe(async _ => {
        await this.fetch(this.page);
      });
  }

  ngAfterViewInit() {
    // scroll to selection
    setTimeout(() => {
      const h = parseInt(
        this.activatedRoute.snapshot.queryParamMap.get('h') as string
      );
      if (h) {
        document!.querySelector('#scroll-area')!.scrollTop = h;
      }
    }, 300);
  }
  private registerListeners() {
    this.filteredSubjects$ = this.reloadFilteredSubjects$.pipe(
      switchMap(() =>
        from(
          this.subjectControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterSubjects(value || ''))
          )
        )
      )
    );

    this.locations$ = this.controls.location.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      filter(query => !!query && query.length > 2),
      switchMap(value => this.locationsService.searchAddress(value!)),
      filter(r => r.status === 'OK'),
      map(result => result.results)
    );

    this.controls.location.valueChanges
      .pipe(
        startWith(''),
        distinctUntilChanged(),
        skip(1),
        filter(query => !query || query.length === 0)
      )
      .subscribe(_ => {
        this.selectedLocation = undefined;
        this.removeFilter(this.controls.location, '', 'location');
      });

    this.controls.serach.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('query', value!);
        await this.reload();
      });

    this.controls.radius.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('radius', value!);
        await this.reload();
      });

    this.controls.isFulltimeJob.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('isFullTimeJob', value!);
        await this.reload();
      });

    this.controls.jobFunctions.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('jobFunctions', JSON.stringify(value));
        await this.reload();
      });

    this.controls.levels.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('levels', JSON.stringify(value));
        await this.reload();
      });

    this.controls.levelDescriptions.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams(
          'levelDescriptions',
          JSON.stringify(value)
        );
        await this.reload();
      });

    this.controls.startTime.valueChanges
      .pipe(
        startWith(''),
        skip(1),
        distinctUntilChanged(),
        filter(value => !!value)
      )
      .subscribe(async value => {
        this.updateQueryParams('startTime', getUnixTime(value as Date));
        await this.reload();
      });

    this.controls.endTime.valueChanges
      .pipe(
        startWith(''),
        skip(1),
        distinctUntilChanged(),
        filter(value => !!value)
      )
      .subscribe(async value => {
        await this.updateQueryParams('endTime', getUnixTime(value as Date));
        await this.reload();
      });
  }

  private setInputFieldsFromQueryParams(queryParamMap: ParamMap) {
    if (queryParamMap.get('location')) {
      this.selectedLocation = JSON.parse(
        queryParamMap.get('location')!
      ) as GeocodeResult;
      this.controls.location.setValue(queryParamMap.get('location'));
    }

    if (queryParamMap.get('subjects')) {
      this.selectedSubjects = JSON.parse(queryParamMap.get('subjects')!);
    }

    if (queryParamMap.get('query')) {
      this.controls.serach.setValue(queryParamMap.get('query'));
    }

    if (queryParamMap.get('radius')) {
      this.controls.radius.setValue(parseInt(queryParamMap.get('radius')!));
    }

    if (queryParamMap.get('isFullTimeJob')) {
      this.controls.isFulltimeJob.setValue(
        JSON.parse(queryParamMap.get('isFullTimeJob')!)
      );
    }

    if (queryParamMap.get('jobFunctions')) {
      this.controls.jobFunctions.setValue(
        JSON.parse(queryParamMap.get('jobFunctions')!)
      );
    }

    if (queryParamMap.get('levels')) {
      this.controls.levels.setValue(JSON.parse(queryParamMap.get('levels')!));
    }

    if (queryParamMap.get('levelDescriptions')) {
      this.controls.levelDescriptions.setValue(
        JSON.parse(queryParamMap.get('levelDescriptions')!)
      );
    }

    if (queryParamMap.get('startTime')) {
      const d = new Date(
        fromUnixTime(parseInt(queryParamMap.get('startTime')!))
      );
      this.controls.startTime.setValue(d);
    }

    if (queryParamMap.get('endTime')) {
      const d = new Date(fromUnixTime(parseInt(queryParamMap.get('endTime')!)));
      this.controls.endTime.setValue(d);
    }
  }

  private async loadInitalData(queryParamMap: ParamMap) {
    const s = queryParamMap.get('s') || '0';
    const selectionIndex = parseInt(s);
    const countPages = Math.ceil(selectionIndex / this.defaultHitsPerPage) + 1;
    for (let page = 0; page < countPages; page++) {
      await this.fetch(page);
    }
  }

  async reload() {
    this.page = 0;
    await this.updateQueryParams('s');
  }

  async setLocation(geocode: GeocodeResult) {
    this.selectedLocation = geocode;
    await this.updateQueryParams('location', JSON.stringify(geocode));
    await this.reload();
  }

  displayLocationFn(result: GeocodeResult): string {
    return result && result.formatted_address ? result.formatted_address : '';
  }

  getRegionalFilters(props: {
    radius: number | undefined | null;
    location: GeocodeResult | undefined;
  }) {
    const filters: string[][] = [[]];
    if ((!props.radius || props.radius == 0) && props.location) {
      const canton = props.location.address_components.find(x =>
        x.types.includes(PlaceType2.administrative_area_level_1)
      )?.long_name;

      const city =
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.locality)
        )?.long_name ||
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.administrative_area_level_2)
        )?.long_name;

      const zipcode = props.location.address_components.find(x =>
        x.types.includes(PlaceType2.postal_code)
      )?.long_name;

      if (zipcode) {
        filters[0].push(`schoolAddress.zipcode:${zipcode}`);
      }
      if (city) {
        filters[0].push(`schoolAddress.city:${city}`);
      }

      if (canton && !city && !zipcode) {
        filters[0].push(`schoolAddress.canton:${canton}`);
        filters[0].push(`schoolAddress.canton:Kanton ${canton}`);
        filters[0].push(
          `schoolAddress.canton:${canton?.replace('Kanton ', '')}`
        );
      }
    }

    return filters;
  }

  enrichJobfunctions(jobFunctions: string[]) {
    const functions = [...jobFunctions];
    if (functions.includes('Lehrperson')) {
      pushIfNotExists(functions, 'Klassenlehrperson');
      pushIfNotExists(functions, 'Fachlehrperson');
    }

    return functions;
  }

  getFacetFilters() {
    let levels = this.controls.levels.value || [];
    levels = this.schoolService.getZyklusLevelsFilter(levels);

    const jobFunctions = this.enrichJobfunctions(
      this.controls.jobFunctions.value || []
    );
    const levelDescriptions = this.controls.levelDescriptions.value || [];
    const subjects = this.selectedSubjects || [];
    const isFullTimeJob =
      this.controls.isFulltimeJob.value === null
        ? null
        : !!this.controls.isFulltimeJob.value;

    const radius = this.controls.radius.value;
    const regionalFilters = this.getRegionalFilters({
      radius,
      location: this.selectedLocation,
    });

    let filters = [
      ['isPublic:true'],
      ['isClosed:false'],
      ...regionalFilters,
      subjects.map(t => `subjects:${t}`),
      jobFunctions.map(t => `function:${t}`),
      levels.map(t => `schoolLevels:${t}`),
      levelDescriptions.map(t => `schoolLevelDescriptions:${t}`),
    ] as any[];

    if (isFullTimeJob !== null) {
      filters = [...filters, `isFullTimeJob:${isFullTimeJob}`];
    }

    return filters;
  }

  getDateRangeFilters() {
    const startTime =
      this.controls.startTime.value || new Date('July 01, 2022 00:00:00');
    // add 23:59 to endtime
    const endTime = this.controls.endTime.value
      ? addMinutes(this.controls.endTime.value, 1439)
      : new Date('July 21, 2243 00:00:00');
    const startTimeUnix = getUnixTime(startTime);
    const endTimeUnix = getUnixTime(endTime);

    return `(applicationStartDateUnix:${startTimeUnix} TO ${endTimeUnix}) OR (durationFromUnix:${startTimeUnix} TO ${endTimeUnix})`;
  }

  getRadius() {
    return this.controls.radius.value || undefined;
  }

  getLatLng() {
    if (this.selectedLocation?.geometry.location) {
      const { lat, lng } = this.selectedLocation.geometry.location;
      return `${lat}, ${lng}`;
    }

    return undefined;
  }

  removeSubject(subject: any) {
    const i = this.selectedSubjects.indexOf(subject);
    this.selectedSubjects.splice(i, 1);
    this.updateQueryParams('subjects', JSON.stringify(this.selectedSubjects));
    this.reloadFilteredSubjects$.next(1);
  }

  onSelectSubject(event: MatAutocompleteSelectedEvent) {
    this.subjectControl.setValue('');
    if (!this.selectedSubjects.includes(event.option.value)) {
      this.selectedSubjects.push(event.option.value);
    }
    this.updateQueryParams('subjects', JSON.stringify(this.selectedSubjects));
    this.reloadFilteredSubjects$.next(1);
    this.reload();
  }

  private filterSubjects(value: string) {
    const filterValue = value.toLowerCase();

    return this.subjects
      .filter(sub => !this.selectedSubjects.includes(sub as any))
      .filter(sub => sub.toLowerCase().includes(filterValue));
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  async removeFilter(control: FormControl, value: unknown, key: string) {
    if (Array.isArray(control.value)) {
      const i = control.value.indexOf(value);
      if (i > -1) control.value.splice(i, 1);
    } else {
      control.setValue('');
    }

    if (control === this.controls.location) {
      this.selectedLocation = undefined;
    }

    await this.updateQueryParams(key);
    await this.reload();
  }

  async fetch(page: number) {
    this.page = page;
    const result = await this.jobService.search(
      this.controls.serach.value || '',
      {
        filters: this.getDateRangeFilters(),
        facetFilters: this.getFacetFilters(),
        aroundRadius: this.getRadius(),
        aroundLatLng: this.getLatLng(),
        hitsPerPage: this.defaultHitsPerPage,
        page: this.page,
      }
    );
    this.maxPage = result.nbPages - 1;
    if (this.page === 0) {
      this.jobs = result.hits;
    } else {
      this.jobs = [...this.jobs, ...result.hits];
    }
  }

  async fetchNext() {
    await this.fetch(this.page + 1);
  }

  async navigate(event: Event, job: Hit<Job>, index: number) {
    event.preventDefault();
    const h = document.getElementById('scroll-area')?.scrollTop;
    await this.updateQueryParams('h', h);
    await this.updateQueryParams('s', index);
    await this.router.navigate(['/jobs', job.objectID], {
      queryParamsHandling: 'merge',
    });
  }

  private async updateQueryParams(
    key: string,
    value?: string | number | boolean
  ) {
    const queryParams = {} as Record<string, unknown>;
    queryParams[key] = value;

    return this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams,
    });
  }
}
