import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Job } from '../../types/job';
import {
  getCityWithCantonAbbrevation,
  getPublicFileUrl,
  hasObjectOnlyEmptyProps,
} from '../../core/helpers';
import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import { JobService } from 'src/app/services/job.service';
import { DatePipe } from '@angular/common';

export type JobContextMenuItem = {
  id: string;
  icon: string;
  text: string;
};

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss'],
})
export class JobCardComponent {
  @Input({ required: true }) job!: Job;
  @Input() hasHover: boolean = false;
  @Input() menuItems: JobContextMenuItem[] = [];
  @Input() isProcessing: boolean = false;
  @Output() clickContextItem = new EventEmitter<string>();

  constructor(
    public jobService: JobService,
    private datePipe: DatePipe
  ) {}

  getAvatarUrl() {
    if (this.job?.schoolId) {
      return getPublicFileUrl('schools', this.job?.schoolId, 'avatar');
    }
    return '';
  }

  clickContextMenuItem(itemId: string) {
    this.clickContextItem.emit(itemId);
  }

  getTitle(job: Job) {
    return this.jobService.getLevelsTitle(job.schoolLevels || [], []);
  }

  getHumanizedTime() {
    if (this.job?.modifiedOn) {
      const d = Date.parse(this.job.modifiedOn!);
      const str = formatDistanceToNow(d, { addSuffix: true, locale: de });
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return '';
  }

  getJobType() {
    return this.job.isFullTimeJob ? 'Festanstellung' : 'Stellvertretung';
  }

  getWorkloadAndDurationText(job: Job) {
    // Generate pensum text
    let pensumText = '';

    if (
      job.activityRangeInPercentage &&
      !hasObjectOnlyEmptyProps(job.activityRangeInPercentage)
    ) {
      const { from, to } = job.activityRangeInPercentage;
      pensumText = from === to ? `${from}%` : `${from} - ${to}%`;
    } else if (
      job.activityRangeInHours &&
      !hasObjectOnlyEmptyProps(job.activityRangeInHours)
    ) {
      const { from, to } = job.activityRangeInHours;
      pensumText =
        from === to ? `${from} Lektionen` : `${from} - ${to} Lektionen`;
    } else {
      pensumText = '';
    }

    // Generate duration text
    const formatShort = 'dd.MM.';
    const formatLong = 'dd.MM.yy';
    let durationText = '';

    if (job.isFullTimeJob || !job.durationTo) {
      durationText = job.isDurationStartNow
        ? 'Ab sofort'
        : `Ab ${this.datePipe.transform(job.durationFrom, formatLong)}` || '';
    } else {
      const from = job.isDurationStartNow
        ? 'Ab sofort'
        : this.datePipe.transform(job.durationFrom, formatShort) || '';
      const to = this.datePipe.transform(job.durationTo, formatLong) || '';
      durationText = `${from} - ${to}`;
    }

    // Combine them
    return `${pensumText}${pensumText ? ' | ' : ''}${durationText}`;
  }

  getJobLocation() {
    return getCityWithCantonAbbrevation(this.job.schoolAddress);
  }
}
