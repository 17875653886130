import { PartialStateUpdater, patchState, signalState } from '@ngrx/signals';

type ChatState = {
  init: boolean;
  profiles: string[];
  page: number;
};

export const chatState = signalState<ChatState>({
  init: false,
  profiles: [],
  page: 0,
});

const setPage = (page?: number): PartialStateUpdater<{ page: number }> => {
  return state => ({ ...state, page: page ?? state.page + 1 });
};

export const patchChatState = (data: Partial<ChatState>): void => {
  patchState(chatState, state => ({ ...state, ...data }));
};

export const patchChatPage = (page?: number) => {
  patchState(chatState, setPage(page));
};
