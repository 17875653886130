@if (!showEmptyCard && !loading) {
  <div class="relative">
    <app-video-player
      [hls]="school.video?.hls"
      type="video/mp4"></app-video-player>
    @if (isOwner) {
      <button
        (click)="onRemove()"
        class="absolute top-2 right-2 z-[1000]"
        mat-icon-button>
        <mat-icon class="material-symbols-rounded text-white">delete</mat-icon>
      </button>
    }
  </div>
}

@if ((showEmptyCard && this.isOwner) || loading) {
  <input
    type="file"
    class="hidden"
    [accept]="acceptTypes"
    (change)="upload($event)"
    #fileUpload />
  <app-empty-card
    title="Schulvideo"
    [buttonText]="buttonText"
    icon="upload"
    subtitle="Deine Schule stellt sich vor."
    description="Zeige einen authentischen Einblick, wie euer Team und eure Schüler lernen und leben."
    (triggerAction)="onUpload()"
    [loading]="loading"
    [fullWidthAlways]="true">
  </app-empty-card>
}
