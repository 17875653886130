<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Schulentwicklung & -betrieb</h1>

      @if (!edit) {
        <span class="text-base text-bn-bright">
          Lade hier spannende <strong>Dokumente</strong> hoch und wähle per Drag
          and Drop die Reihenfolge! Beispiele: Schulentwicklungsperlen,
          Evaluationsbericht, Jahres- oder Schulprogramm und Sitzungskalender.
        </span>
      }
    </div>

    <div class="flex flex-col xl:flex-row justify-end gap-y-8">
      <div class="flex items-center justify-between gap-x-8 xl:order-2">
        @if (!edit) {
          <a mat-button [routerLink]="['/schools', id]" [disabled]="loading">
            Abbrechen
          </a>
          <app-action-button
            [loading]="loading"
            [disabled]="!docsValid() || edit"
            (onClick)="save()"
            text="Speichern" />
        } @else {
          <button mat-button (click)="toggleMode(true)" [disabled]="loading">
            Abbrechen
          </button>
        }
      </div>
      <div class="flex-1">
        <button
          mat-stroked-button
          color="primary"
          class="mdc-button--light mdc-button--grey w-full xl:w-auto xl:order-1"
          (click)="toggleMode()"
          [disabled]="documents.length <= 1">
          {{ edit ? 'Reihenfolge übernehmen' : 'Reihenfolge anpassen' }}
        </button>
      </div>
    </div>

    <section class="flex flex-col gap-y-5">
      @if (!edit) {
        <header class="card">
          <input
            hidden
            type="file"
            [disabled]="loading"
            [accept]="acceptTypes"
            (change)="selectFiles($event)"
            #file />
          <mat-form-field class="w-full cursor-pointer" (click)="file.click()">
            <input
              class="cursor-pointer"
              placeholder="Datei auswählen"
              matInput
              readonly
              [formControl]="upload" />
            <button matSuffix mat-icon-button>
              <mat-icon class="material-symbols-rounded">upload</mat-icon>
            </button>
            <mat-error *ngIf="upload.hasError('maxCount')">
              Es sind max. {{ max }} Anhänge erlaubt
            </mat-error>
          </mat-form-field>
        </header>
      }

      @if (documents.length) {
        <ul
          class="flex flex-col gap-y-5"
          cdkDropList
          (cdkDropListDropped)="sortDocuments($event)">
          @for (document of documents; track document; let i = $index) {
            <li
              cdkDrag
              class="card"
              [ngClass]="{ 'card--edit': edit }"
              [cdkDragData]="i"
              [cdkDragDisabled]="loading || !edit">
              <article class="document">
                <header class="document__header">
                  <div class="document__header__title">
                    <span class="document__header__title__icon">
                      <mat-icon class="material-symbols-rounded">
                        description
                      </mat-icon>
                    </span>
                    <p class="document__header__title__filename">
                      {{ document.document?.filename }}
                    </p>
                  </div>
                  <aside class="document__header__meta">
                    @if (edit) {
                      <button
                        cdkDragHandle
                        mat-stroked-button
                        class="mdc-button--grey document__header__meta__grab">
                        <mat-icon
                          class="material-symbols-rounded scale-125 m-0">
                          drag_indicator
                        </mat-icon>
                      </button>
                    } @else {
                      <button
                        mat-stroked-button
                        color="primary"
                        class="mdc-button--light mdc-button--grey"
                        (click)="deleteDocument(i)">
                        <mat-icon class="material-symbols-rounded scale-125">
                          delete
                        </mat-icon>
                        Löschen
                      </button>
                    }
                  </aside>
                </header>
                <main class="document__main" [class.hidden]="edit">
                  <mat-form-field class="w-full">
                    <mat-label>Titel</mat-label>
                    <input
                      matInput
                      type="text"
                      [value]="document.title"
                      [formControl]="document.title" />
                    <mat-error>Füge einen Titel hinzu.</mat-error>
                  </mat-form-field>
                </main>
              </article>
            </li>
          }
        </ul>
      }
    </section>

    @if (!edit) {
      <div class="flex items-center">
        <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
          info
        </mat-icon>
        <div class="text-base text-bn-bright">
          Die Anhänge sollten maximal 5 MB sein. Unterstützt werden folgende
          Formate: PDF, DOC und DOCX.
        </div>
      </div>
    }
  </div>
</div>
