import { PartialStateUpdater, patchState, signalState } from '@ngrx/signals';

type DeviceState = {
  isMobile: boolean;
};

export const deviceState = signalState<DeviceState>({
  isMobile: true,
});

const setIsMobile = (
  isMobile: boolean = true
): PartialStateUpdater<{ isMobile: boolean }> => {
  return state => ({ ...state, isMobile });
};

export const patchDeviceState = (isMobile?: boolean) => {
  patchState(deviceState, setIsMobile(isMobile));
};
