<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Schullogo</h1>
      <span class="text-base text-[#B5B6C1]">
        Du darfst ein Bild deiner Wahl hochladen. Eignen würde sich hier ggf.
        ein Logo, bspw. das Logo eurer Schulgemeinde oder das deiner
        Schuleinheit. Mit dem Auswahlfeld und Zoom wählst du deinen perfekten
        Bildausschnitt.
      </span>
    </div>
    <div class="flex flex-col gap-8">
      <input
        type="file"
        class="hidden"
        [accept]="acceptTypes"
        (change)="fileChangeEvent($event)"
        #fileUpload />
      <div class="flex flex-col">
        @if (!imageChangedEvent) {
          <div class="flex justify-center">
            <img
              alt="Schullogo"
              class="border-2 border-solid border-color rounded-3xl w-[220px] h-[220px]"
              [src]="getImageUrl()" />
          </div>
        } @else {
          <div class="flex items-stretch justify-end w-full">
            <image-cropper
              alignImage="center"
              format="jpeg"
              backgroundColor="#fff"
              class="image-cropper"
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="1 / 1"
              (imageCropped)="imageCropped($event)"
              [imageQuality]="100"
              [onlyScaleDown]="true"
              [resizeToWidth]="600"
              [resizeToHeight]="600"
              [containWithinAspectRatio]="true"
              [allowMoveImage]="true"
              [(transform)]="transform" />
          </div>
        }
      </div>
      <div class="flex flex-col xl:flex-row gap-4 items-center justify-between">
        <div class="flex gap-4 order-2 xl:order-1">
          <button
            mat-stroked-button
            color="primary"
            (click)="fileUpload.click()">
            <mat-icon class="material-symbols-rounded">upload</mat-icon>
            Foto wählen
          </button>
          @if (!imageChangedEvent) {
            <button
              mat-stroked-button
              color="primary"
              (click)="clear()"
              [disabled]="!media.displayUrl">
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
              Löschen
            </button>
          } @else {
            <button mat-stroked-button color="primary" (click)="reset()">
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
              Löschen
            </button>
          }
        </div>

        @if (imageChangedEvent) {
          <div class="flex order-1 xl:order-2">
            <button mat-icon-button color="grey" (click)="zoomOut()">
              <mat-icon class="material-symbols-rounded"> zoom_out </mat-icon>
            </button>
            <button mat-icon-button color="grey" (click)="zoomIn()">
              <mat-icon class="material-symbols-rounded"> zoom_in </mat-icon>
            </button>
          </div>
        }
      </div>

      <div class="flex items-center">
        <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
          info
        </mat-icon>
        <div class="text-base text-[#B5B6C1]">
          Mitglieder müssen auf Bildigsnetz ihre echten Identitäten nutzen. Nimm
          also ein Foto von dir auf oder lade ein vorhandenes Foto von dir hoch.
          Mögliche Dateiformate: JPG, BMP, PNG (max. 20 MB) Empfohlene
          Auflösung: 1024 x 1024 px
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/schools', id]">
        Abbrechen
      </a>
      <app-action-button
        [disabled]="!imageChangedEvent && !deleted"
        (onClick)="save()"
        text="Speichern" />
    </div>
  </div>
</div>
