import { environment } from '../../environments/environment';
import { School } from '../types/school';

/**
 * Returns true if school is non administrative.
 * Example: school is root and has no locations or it is a non-root location.
 * @param school
 * @returns boolean
 */
export function isNonAdministrativeSchoolUnit(school: School): boolean {
  const countLocations = school.locations?.length as number;
  if (school.isRoot && countLocations === 0) return true;
  if (!school.isRoot) return true;

  return false;
}

/**
 * Groups array by key
 * @param list
 * @param key
 * @returns Groups
 */
export function groupby<TItem>(
  list: TItem[],
  key: string
): { [key: string]: TItem[] } {
  return list.reduce(function (rv: any, item: any) {
    (rv[item[key]] = rv[item[key]] || []).push(item);
    return rv;
  }, {});
}

/**
 * Returns download url of public bucket files.
 * @param entity School or Profile
 * @param id Id of entity
 * @param file file name
 * @returns url
 */
export function getPublicFileUrl(entity: string, id: string, file: string) {
  return getPublicFileUrlBase(`${entity}/${id}/${file}`);
}

/**
 * Returns download url of public bucket files by file path
 * @param filePath file path of bucket file
 * @returns url
 */
export function getPublicFileUrlByFilePath(filePath: string) {
  let path = filePath;
  if (path.startsWith('/')) {
    path = path.substring(1);
  }

  return getPublicFileUrlBase(path);
}

function getPublicFileUrlBase(path: string) {
  const ressourceUri = encodeURIComponent(path);
  const t = localStorage.getItem('TICK') || 0;
  return `${environment.storageBaseUrl}/${ressourceUri}?alt=media&t=${t}`;
}

/**
 * Translates english months to german
 * @param month Month to translate in German
 */
export function translateMonths(month: string) {
  const months = {
    January: 'Januar',
    February: 'Februar',
    March: 'März',
    April: 'April',
    May: 'Mai',
    June: 'Juni',
    July: 'Juli',
    August: 'August',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'Dezember',
  };

  const i = Object.keys(months).indexOf(month);
  if (i === -1) return month;

  return Object.values(months)[i];
}

/**
 * Scale image by max width and height and keep aspect ratio
 */
export async function scaleDataURL(
  dataURL: string,
  maxWidth: number,
  maxHeight: number
): Promise<Blob> {
  return new Promise<Blob>(done => {
    const img = new Image();
    img.onload = () => {
      let scale = 1;
      if (img.width > img.height && img.width > maxWidth) {
        scale = maxWidth / img.width;
      } else if (img.height > img.width && img.height > maxHeight) {
        scale = maxHeight / img.height;
      }

      const newWidth = img.width * scale;
      const newHeight = img.height * scale;
      const canvas = document.createElement('canvas');
      canvas.height = newHeight;
      canvas.width = newWidth;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          newWidth,
          newHeight
        );
        canvas.toBlob(
          blob => {
            if (blob) done(blob);
          },
          'image/webp',
          0.95
        );
      }
    };
    img.src = dataURL;
  });
}

/**
 * Returns true if object is empty.
 * @param obj
 * @returns boolean
 */
export function isObjectEmpty(obj: any) {
  return (
    Object.getOwnPropertyNames(obj).length === 0 &&
    Object.getOwnPropertySymbols(obj).length === 0
  );
}

/**
 * Returns true if object has only empty properties.
 * @param obj
 * @returns boolean
 */
export function hasObjectOnlyEmptyProps(obj: any) {
  if (isObjectEmpty(obj)) {
    return true;
  }

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0) &&
        !(value.constructor === Object && Object.keys(value).length === 0)
      ) {
        // Found a non-empty property
        return false;
      }
    }
  }
  // All properties are empty
  return true;
}

/**
 * Pushes value to array if not already in array.
 * @param arr Array
 * @param value Value
 */
export function pushIfNotExists<T>(arr: T[], value: T) {
  if (!arr.includes(value)) {
    arr.push(value);
  }
}

export function getCityWithCantonAbbrevation(
  input?: {
    city?: string;
    cantonShort?: string;
  } | null
) {
  if (!input?.city) {
    return '';
  }

  const cantonAbbr = input.cantonShort ?? '';

  return `${input.city}${cantonAbbr ? ` (${cantonAbbr})` : ''}`;
}
