import { Component, Input } from '@angular/core';
import { SchoolTypes } from '../../types/school';

@Component({
  selector: 'app-school-type-tag',
  templateUrl: './school-type-tag.component.html',
  styleUrls: ['./school-type-tag.component.scss'],
})
export class SchoolTypeTagComponent {
  @Input({ required: true }) type!: (typeof SchoolTypes)[number];

  getColor() {
    switch (this.type) {
      case 'Privatschule':
        return '#EFE1FF';
      case 'Sonderschule':
        return '#FFF4A8';
      default:
        return '#DFF5AB';
    }
  }
}
