import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LocationsService } from '../../../services/locations.service';
import { ProfileService } from '../../../services/profile.service';
import { GeoLocation } from '../../../types/core';
import { Profile } from '../../../types/profile';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
} from 'rxjs';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-settings-contact',
  templateUrl: './settings-contact.component.html',
  styleUrls: ['./settings-contact.component.scss'],
})
export class SettingsContactComponent {
  location?: GeoLocation;
  profile?: Profile;
  loading = false;
  userHasEmailAuth: boolean;

  contactForm = this.fb.group({
    zipcode: ['', [Validators.required]],
    city: ['', [Validators.required]],
    email: ['', []],
    phone: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    private profileService: ProfileService,
    private router: Router,
    breadcrumbService: BreadcrumbService,
    settingService: SettingService
  ) {
    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Nutzerkonto', link: 'settings/account' },
      { text: 'Kontaktdaten' },
    ]);

    this.contactForm.controls.email.disable();
    this.contactForm.controls.zipcode.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        filter(zipcode => !!zipcode && zipcode.length === 4),
        switchMap(zipcode =>
          this.locationsService.searchAddress(`Schweiz ${zipcode}`)
        ),
        filter(r => r.status === 'OK')
      )
      .pipe(switchMap(result => this.locationsService.getLocation(result)))
      .subscribe(location => {
        if (location) {
          this.location = location;
          this.contactForm.controls.city.setValue(location.city!);
        }
      });

    profileService.getMe(false).subscribe(p => {
      this.profile = p;
      profileService.getContact().subscribe(c => {
        this.contactForm.setValue({
          zipcode: p.zipcode || '',
          city: p.city || '',
          email: c.email || '',
          phone: c.phone || '',
        });
      });
    });

    this.userHasEmailAuth = settingService.userHasEmailAuthProvider();
  }

  save() {
    this.loading = true;
    const { email: _email, phone, ...contact } = this.contactForm.value;
    const profile = {
      ...contact,
      _geoloc: this.location?.geoloc || null,
      canton: this.location?.canton?.replace('Kanton ', '') || '',
      cantonShort: this.location?.cantonShort || '',
      municipality: this.location?.municipality || '',
    } as Partial<Profile>;
    this.profileService.update(profile).subscribe(() => {
      this.profileService
        .updateContact({ phone: phone || '' })
        .subscribe(() => {
          this.loading = false;
          this.notificationService.success('Kontaktdaten gespeichert.');
          this.router.navigate(['/settings/account']);
        });
    });
  }
}
