import { Component, inject, Input, OnInit } from '@angular/core';
import { School } from '../../../../types/school';
import { getPublicFileUrl } from '../../../../core/helpers';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { Router } from '@angular/router';

import { StreamChannelService } from '../../../../../modules/chat/services/stream-channel.service';
import { EMPTY, from, Observable } from 'rxjs';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-school-contact-card',
  templateUrl: './school-contact-card.component.html',
  styleUrls: ['./school-contact-card.component.scss'],
})
export class SchoolContactCardComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  defaultSrc = 'assets/defaults/avatar.svg';
  permissionLink?: string;
  isAuthorized$: Observable<boolean> = EMPTY;

  private readonly analytics = inject(Analytics);
  private readonly streamChannelService = inject(StreamChannelService);
  private readonly router = inject(Router);

  constructor(profileService: ProfileService) {
    this.isAuthorized$ = profileService.isAuthorized();
  }

  ngOnInit(): void {
    this.permissionLink = `/schools/${this.school.rootId}/edit/permissions`;
  }

  get showEmptyCard() {
    return !this.school.contacts || this.school.contacts.length <= 0;
  }

  getImageUrl(userId: string) {
    return getPublicFileUrl('profiles', userId, 'avatar');
  }

  getTitle() {
    const singular = 'Ansprechperson';
    return this.school.contacts?.length === 1 ? singular : `${singular}en`;
  }

  async openChat(uid?: string): Promise<void> {
    if (uid) {
      from(this.streamChannelService.create([uid])).subscribe(
        async ({ id }) => {
          logEvent(this.analytics, 'open_chat');
          await this.router.navigate(['/chat/overview', id]);
        }
      );
    }
  }
}
