<div
  class="flex flex-col border-[1.5px] rounded-2xl h-full w-full p-5"
  [ngClass]="{
    'bg-[#E1EDFF]': job.isFullTimeJob,
    'border-[#E1EDFF]': job.isFullTimeJob,
    'bg-[#F5DFE1]': !job.isFullTimeJob,
    'border-[#F5DFE1]': !job.isFullTimeJob,
    'job-card': hasHover
  }">
  <div class="flex justify-between">
    <div class="rounded-xl">
      <div
        class="h-20 w-20 rounded-xl bg-center bg-no-repeat bg-contain"
        [ngStyle]="{
          'background-image':
            'url(' +
            getAvatarUrl() +
            '), url(/assets/defaults/school_avatar.svg)'
        }"></div>
    </div>
    <div class="flex flex-col items-end justify-between">
      <div class="flex justify-end w-full">
        <div
          class="h-10 w-10 rounded-full flex justify-center items-center"
          [ngClass]="job.isFullTimeJob ? 'bg-[#001A41]' : 'bg-[#3B0716]'">
          <mat-icon
            class="material-symbols-rounded"
            [ngClass]="job.isFullTimeJob ? 'text-[#ECF4FF]' : 'text-[#F5DFE1]'">
            {{ job.isFullTimeJob ? 'work' : 'work_history' }}
          </mat-icon>
        </div>
        @if (menuItems.length > 0) {
          @if (!isProcessing) {
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()">
              <mat-icon class="material-symbols-rounded font-bold"
                >more_vert</mat-icon
              >
            </button>
            <mat-menu #menu>
              @for (menuItem of menuItems; track menuItem.id) {
                <button
                  mat-menu-item
                  (click)="
                    $event.stopPropagation(); clickContextMenuItem(menuItem.id)
                  ">
                  <mat-icon>{{ menuItem.icon }}</mat-icon>
                  <span>{{ menuItem.text }}</span>
                </button>
              }
            </mat-menu>
          } @else {
            <mat-spinner class="m-[5px]" diameter="30"></mat-spinner>
          }
        }
      </div>
      @if (job.isPublic && !job.isClosed) {
        <span class="bright-font text-[12px]">
          <span class="primary-color">•</span>
          {{ getHumanizedTime() }}
        </span>
      }
    </div>
  </div>
  <div class="flex flex-col mt-5 gap-5">
    <div>
      <strong
        class="text-2xl line-clamp-2 text-ellipsis"
        [ngClass]="job.isFullTimeJob ? 'text-[#001A41]' : 'text-[#3B0716]'">
        {{ job.function }}</strong
      >
      <span class="text-base bright-font line-clamp-1 text-ellipsis">
        {{ getTitle(job) }}
      </span>
    </div>
    <div class="text-base bright-font">
      <span class="font-bold line-clamp-1 text-ellipsis">{{
        getJobType()
      }}</span>
      <span class="line-clamp-1 text-ellipsis">
        {{ getWorkloadAndDurationText(job) }}
      </span>
    </div>
    <div class="text-base bright-font">
      <span class="font-bold line-clamp-1 text-ellipsis">
        {{ job.schoolName }}
      </span>
      <span class="line-clamp-1 text-ellipsis">
        {{ getJobLocation() }}
      </span>
    </div>
  </div>
  <ng-content></ng-content>
</div>
