<div class="flex flex-col gap-4 flex-wrap">
  <div
    #textbox
    [ngClass]="getLineClampClass()"
    class="text-ellipsis whitespace-pre-line break-words w-full NgxEditor__View"
    [innerHTML]="html"></div>
  @if (
    expanded ||
    textbox.offsetHeight < textbox.scrollHeight ||
    textbox.offsetWidth < textbox.scrollWidth
  ) {
    <div class="flex justify-start">
      @if (expanded) {
        <button
          mat-stroked-button
          color="primary"
          class="mdc-button--light mdc-button--grey"
          (click)="toggleText()">
          <mat-icon class="material-symbols-rounded scale-125">
            expand_less
          </mat-icon>
          Weniger anzeigen
        </button>
      }
      @if (!expanded) {
        <button
          mat-stroked-button
          color="primary"
          class="mdc-button--light mdc-button--grey"
          (click)="toggleText()">
          <mat-icon class="material-symbols-rounded scale-125">
            expand_more
          </mat-icon>
          Weiterlesen
        </button>
      }
    </div>
  }
</div>
