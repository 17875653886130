import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Auth, signOut } from '@angular/fire/auth';
import { firstValueFrom, from, Subscription, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { PermissionsService } from './services/permissions.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ProfileService } from './services/profile.service';
import { Profile } from './types/profile';
import {
  Analytics,
  logEvent,
  setUserId,
  setAnalyticsCollectionEnabled,
} from '@angular/fire/analytics';

import { StreamUserService } from '../modules/chat/services/stream-user.service';
import { NavigationService } from '../modules/chat/services/navigation.service';
import { ChatClientService } from 'stream-chat-angular';
import { environment } from '../environments/environment';
import { patchChatState } from './signals/state/chat.state';
import { patchDeviceState } from './signals/state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isNavbarOpen = false;
  profile?: Profile;
  permissionsSubscription?: Subscription;
  isSchoolAdmin = false;
  uid?: string;
  isChat: boolean = false;
  logoutInProgress: boolean = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    @Optional() public auth: Auth,
    public router: Router,
    public permissionService: PermissionsService,
    public profileService: ProfileService,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private analytics: Analytics,
    private streamUserService: StreamUserService,
    private navigationService: NavigationService,
    private chatService: ChatClientService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.navigationService.startSaveHistory();
  }

  async ngOnInit() {
    this.profileService.getMe(true).subscribe(p => {
      this.profile = p;
      this.permissionService.loadPermissions(p.id!).subscribe(permissions => {
        this.isSchoolAdmin = permissions?.length > 0;
      });
      this.streamUserService
        .getToken(p.id)
        .pipe(
          switchMap(token =>
            from(
              this.chatService.init(environment.stream.chat.apiKey, p.id, token)
            )
          )
        )
        .subscribe(() => {
          patchChatState({ init: true });
          patchDeviceState(this.mobileQuery.matches);
        });
    });

    this.profileService.getPreferences().subscribe(preferences => {
      setAnalyticsCollectionEnabled(
        this.analytics,
        preferences.analyticTrackingEnabled
      );
    });

    this.navigationService.getCurrentUrl().subscribe(url => {
      this.isChat = url.startsWith('chat');
    });
  }

  async logout() {
    this.logoutInProgress = true;
    try {
      if (this.permissionsSubscription) {
        this.permissionsSubscription.unsubscribe();
      }
      logEvent(this.analytics, 'logout');
      setUserId(this.analytics, null);
      await firstValueFrom(this.streamUserService.logout());
      await signOut(this.auth);
      setTimeout(async () => {
        localStorage.clear();
        await this.router.navigate(['login']);
        window.location.reload();
      }, 300);
    } catch (e) {
      this.logoutInProgress = false;
    }
  }

  toggleNavbar() {
    logEvent(this.analytics, 'toggle_navbar');
    this.isNavbarOpen = !this.isNavbarOpen;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onActivate(_event: any) {
    document!.querySelector('#scroll-area')!.scrollTop = 0;
  }

  public showMobileToolbar(): boolean {
    return this.mobileQuery.matches && !!this.profile && !this.isChat;
  }
}
