import { Component, Input, OnInit } from '@angular/core';
import {
  CollegesOfEducation,
  CollegesOfEducationInfoMap,
  School,
  SchoolTypes,
} from '../../../../types/school';
import { SchoolService } from 'src/app/services/school.service';
import { getCityWithCantonAbbrevation } from 'src/app/core/helpers';

@Component({
  selector: 'app-school-info-card',
  templateUrl: './school-info-card.component.html',
  styleUrls: ['./school-info-card.component.scss'],
})
export class SchoolInfoCardComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  rootSchool: School | undefined = undefined;

  constructor(private schoolService: SchoolService) {}

  ngOnInit(): void {
    // Get root school if current is not already root
    if (!this.school.isRoot) {
      const roolSchoolId = this.school.locations?.find(s => s.isRoot)?.id;

      if (roolSchoolId) {
        this.schoolService.getById(roolSchoolId).subscribe(rs => {
          this.rootSchool = rs;
        });
      }
    } else {
      this.rootSchool = { ...this.school };
    }
  }

  getType(type: (typeof SchoolTypes)[number]) {
    return {
      Privatschule: 'private',
      'Öffentliche Schule': 'public',
      Sonderschule: 'special',
    }[type];
  }

  getIcon(type: (typeof SchoolTypes)[number], name: string) {
    const t = this.getType(type);
    return `custom_${name}_${t}`;
  }

  get showEmptyCard() {
    return (
      !this.rootSchool?.cooperatingCollegeOfEducation &&
      !this.school.countEmployees &&
      !this.school.countStudents &&
      !this.school.address?.website &&
      !this.school.address?.city
    );
  }

  get numberOfLocations() {
    if (this.school.locations && this.school.locations.length > 1) {
      return this.school.locations.length;
    }

    return 1;
  }

  getGmapsLink() {
    const a = this.school.address!;
    return `https://maps.google.com/?q=${a.zipcode}+${a.city}+${a.street}`;
  }

  getSchoolWebsite() {
    const url = this.school.address?.website;
    // add https if not present
    if (url && !url.startsWith('http')) {
      return `https://${url}`;
    }

    if (url?.startsWith('http')) {
      return url;
    }

    return '';
  }

  getCollegeOfEducationWebsite(college: (typeof CollegesOfEducation)[number]) {
    return CollegesOfEducationInfoMap[college].url;
  }

  getLocationText() {
    return getCityWithCantonAbbrevation(this.school.address);
  }
}
