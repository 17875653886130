import { Component, OnInit } from '@angular/core';
import { firstValueFrom, Observable, Subscriber } from 'rxjs';
import { School } from '../../../../types/school';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '../../../../services/files.service';
import { SchoolService } from '../../../../services/school.service';
import { BucketFile } from '../../../../types/core';
import { FormControl, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { deepCopy } from '../../utils/array.util';

export type Document = {
  title: FormControl<string | null>;
  originName: string | null;
  document?: PreviewDocument;
};
export type PreviewDocument = {
  bucket?: BucketFile;
  file?: Blob;
  displayUrl: string;
  filename: string;
  path?: string;
};

@Component({
  selector: 'app-school-documents-form',
  templateUrl: './school-documents-form.component.html',
  styleUrls: ['./school-documents-form.component.scss'],
})
export class SchoolDocumentsFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;
  school?: School;
  loading = false;
  documents: Document[] = [];
  keepSorting: Document[] = [];
  deletableDocuments: Document[] = [];
  upload = new FormControl<string | undefined>(undefined);
  edit: boolean = false;

  readonly max = 6;
  readonly acceptTypes = ['.pdf', '.doc', '.docx'];

  constructor(
    route: ActivatedRoute,
    private schoolService: SchoolService,
    private fileService: FileService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      this.school = school;
      this.documents = (school?.documents ?? []).map(this.bucketFile2Document);
    });
  }

  async uploadDocumentFiles(docs: Document[]) {
    const bucketFiles: BucketFile[] = [];

    for (const doc of docs) {
      if (doc.document?.file && !doc.document.bucket) {
        const randomFolderName = uuid().substring(0, 6);
        const path = `/schools/${this.id}/documents/${randomFolderName}`;

        const bucketFile = await this.fileService.uploadAsync(
          path,
          doc.document.file,
          doc.document.filename,
          true,
          doc.originName ?? undefined
        );

        bucketFiles.push({
          ...bucketFile,
          title: doc.title.value ?? '',
        });
      } else if (doc.document?.bucket) {
        bucketFiles.push({
          ...doc.document?.bucket,
          title: doc.title.value ?? '',
        });
      }
    }

    return bucketFiles;
  }

  async save() {
    this.loading = true;
    try {
      const bucketFiles = await this.uploadDocumentFiles(this.documents);

      for (const doc of this.deletableDocuments) {
        if (doc.document?.bucket?.path) {
          await this.fileService.remove(doc.document.bucket.path);
        }
      }

      await firstValueFrom(
        this.schoolService.update(this.id, { documents: bucketFiles })
      );

      await this.router.navigate(['schools', this.id]);
    } catch (error) {
      console.error('Error while uploading school documents', error);
    } finally {
      this.loading = false;
    }
  }

  toggleMode(abort?: boolean): void {
    if (!abort) this.keepSorting = deepCopy<Document>(this.documents);
    this.edit = !this.edit;
    if (abort && !!this.keepSorting.length) {
      this.documents = deepCopy<Document>(this.keepSorting);
      this.keepSorting = [];
    }
  }

  async selectFiles(event: Event): Promise<void> {
    this.upload.setErrors(null);
    const files = (event.target as HTMLInputElement).files ?? [];

    if (this.documents.length + files.length > this.max) {
      this.upload.setErrors({ maxCount: true });
      return;
    }

    for (const file of files) {
      const document = await firstValueFrom(this.loadDocument(file));
      if (document) this.documents.push(document);
    }
  }

  deleteDocument(index: number): void {
    const doc = this.documents[index];
    this.deletableDocuments.push(doc);
    this.documents.splice(index, 1);
    this.upload.setErrors(null);
  }

  sortDocuments(event: CdkDragDrop<number>): void {
    moveItemInArray(this.documents, event.previousIndex, event.currentIndex);
  }

  docsValid(): boolean {
    return this.upload.valid && this.documents.every(doc => doc.title.valid);
  }

  private bucketFile2Document(file: BucketFile): Document {
    return {
      title: new FormControl<string>(file.title || '', [Validators.required]),
      originName: file.description ?? null,
      document: {
        bucket: file,
        displayUrl: file.url ?? '',
        filename: file.filename ?? '',
      },
    };
  }

  private loadDocument(file?: File): Observable<Document | undefined> {
    const reader = new FileReader();
    return new Observable(
      (observer: Subscriber<Document | undefined>): void => {
        if (file) {
          reader.onload = event =>
            observer.next({
              title: new FormControl<string>('', [Validators.required]),
              originName: file.name,
              document: {
                file,
                filename: file.name,
                displayUrl: event.target?.result as string,
              },
            });
          reader.readAsDataURL(file);
        } else {
          observer.error('No Document Found');
        }
      }
    );
  }
}
