<div class="grid grid-cols-12 md:gap-4">
  <div class="col-span-12 relative">
    <img
      alt="School Background"
      class="h-full w-full rounded-[0.75rem] md:rounded-[1.35rem] object-center object-cover brightness-75"
      [src]="getBackgroundUrl(school)" />
    @if (isOwner) {
      <app-edit-button
        icon="photo_camera"
        [background]="false"
        color="light"
        [routerLink]="['/schools', school.id, 'edit', 'background']"
        class="absolute bottom-1 right-1 md:bottom-3 md:right-4" />
    }
    <div class="flex absolute bottom-4 left-4 md:bottom-8 md:left-8">
      <img
        [ngClass]="{ hidden: hideAvatar }"
        alt="School Avatar"
        class="w-[90px] md:w-[160px] h-[90px] md:h-[160px] rounded-lg md:rounded-[1rem]"
        [src]="getAvatarUrl(school)" />
      @if (isOwner) {
        <app-edit-button
          icon="photo_camera"
          [routerLink]="['/schools', school.id, 'edit', 'avatar']"
          class="absolute -bottom-2.5 left-16 md:left-32 md:-bottom-3 z-10" />
      }
      <div [ngClass]="{ 'pl-8': !hideAvatar }" class="md:flex w-full hidden">
        <div
          class="flex w-full justify-center md:justify-normal flex-col pt-4 self-end">
          <div class="flex gap-4">
            <strong
              class="text-2xl md:text-[2.125rem] md:leading-[50px] text-white font-bold">
              {{ school.name }}
            </strong>
            @if (isOwner) {
              <app-edit-button
                color="light"
                [background]="false"
                [routerLink]="['/schools', school.id, 'edit', 'name']" />
            }
          </div>
          @if (school.type) {
            <app-school-type-tag class="pb-2" [type]="school.type!" />
          }
          <span class="text-base text-white pr-16">
            {{ getSchoolLevelsTitle(school) }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- mobile -->
  <div
    class="flex flex-col col-span-12 md:col-start-3 md:col-span-9 relative md:hidden">
    <div class="flex flex-col items-center md:items-start md:pt-3">
      <div class="flex w-full md:hidden">
        <div
          class="flex w-full justify-center md:justify-normal flex-col pt-4 self-end">
          <div class="flex justify-between gap-4">
            <strong
              class="text-2xl md:text-[2.125rem] leading-[50px] font-bold">
              {{ school.name }}
            </strong>
            @if (isOwner) {
              <app-edit-button
                [routerLink]="['/schools', school.id, 'edit', 'name']" />
            }
          </div>
          <span class="text-base">
            {{ getSchoolLevelsTitle(school) }}
          </span>
          @if (school.type) {
            <app-school-type-tag class="pt-2 pb-2" [type]="school.type!" />
          }
        </div>
      </div>
    </div>
  </div>

  <div class="col-span-12 pb-7 md:pb-6 md:pt-6">
    <app-school-info-card
      class="conditional-hide"
      [school]="school"
      [isOwner]="isOwner" />
  </div>

  @if (locations.length > 0) {
    <app-school-header-locations [locations]="locations" />
  }
</div>
