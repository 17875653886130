@if (job$ | async; as job) {
  <div class="flex justify-center">
    <div class="max-w-[1120px]">
      @if (job.isPublic) {
        <div class="pb-4">
          <a mat-button routerLink="/jobs" queryParamsHandling="preserve">
            <mat-icon class="material-symbols-rounded"
              >keyboard_backspace</mat-icon
            >
            Alle Ergebnisse
          </a>
        </div>
      }
      <div class="flex flex-col gap-16">
        @if (!job.isPublic && isEditable(job)) {
          <app-job-progress-card
            [isOwner]="isOwner"
            [job]="job"
            (publish)="publish()"></app-job-progress-card>
        }
        <app-job-header [job]="job" [isEditable]="isEditable(job)" />
        <div class="grid grid-cols-12 md:gap-4">
          <div class="flex justify-end col-span-10 gap-4">
            <a
              [routerLink]="['/schools', job.schoolId]"
              mat-stroked-button
              color="primary">
              Schulprofil
            </a>
            @if (showDiscardButton(job)) {
              <app-action-button
                [loading]="loadingDiscard"
                [disabled]="isLoading"
                (onClick)="discard(job)"
                customBgColor="#E6155D"
                text="Verwerfen"></app-action-button>
            }
            @if (showArchiveButton(job)) {
              <app-action-button
                [loading]="loadingClose"
                [disabled]="isLoading"
                (onClick)="close()"
                customBgColor="#E6155D"
                text="Archivieren"></app-action-button>
            }
            @if ((isAuthorized$ | async) && showApplyButton(job)) {
              <a mat-flat-button color="primary" routerLink="apply">
                Bewerben
              </a>
            }
            @if ((isAuthorized$ | async) === false && showApplyButton(job)) {
              <a mat-flat-button color="primary" routerLink="apply/public">
                Bewerben
              </a>
            }
          </div>
        </div>
        @if (shouldRenderChild(!jobService.isWorkdayMatrixEmpty(job), job)) {
          <app-job-workload-matrix-form
            id="workload-matrix"
            [job]="job"
            [isEditable]="isEditable(job)"
            (changeWorkdayMatrix)="reload()" />
        }
        @if (shouldRenderChild(!!job.about, job)) {
          <app-job-about-card [job]="job" [isEditable]="isEditable(job)" />
        }
        @if (shouldRenderChild(!!job.weOffer, job)) {
          <app-job-we-offer-card [job]="job" [isEditable]="isEditable(job)" />
        }
        @if (shouldRenderChild(!!job.yourTasks, job)) {
          <app-job-tasks-card [job]="job" [isEditable]="isEditable(job)" />
        }
        @if (shouldRenderChild(!!job.yourSkills, job)) {
          <app-job-skills-card [job]="job" [isEditable]="isEditable(job)" />
        }
      </div>
    </div>
  </div>
}
